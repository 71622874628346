import React, { createContext, useCallback, useContext, useState } from 'react';
import { Close } from '@ghosts-inc/design-system/icons';
import { AlertProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';

/**
 * Snackbar title, text, and severity.
 */
type ShowSnackbarProps = {
  title: string;
  text?: string | null;
  severity: AlertProps['severity'];
  isFullWidth?: boolean;
};

/**
 * Initialize with empty value.
 */
const SnackbarContext = createContext<{
  showSnackbar: (args: ShowSnackbarProps) => void;
}>({
  showSnackbar: () => {},
});

// Used for fullWidth snackbar
const getSnackbarColor = (severity: AlertProps['severity']) => {
  switch (severity) {
    case 'success':
      return 'success.main';
    case 'error':
      return 'error.main';
    default:
      return 'info.blue';
  }
};

/**
 * Returns a function that can be called to show the global snackbar, with updated values
 * specified by the caller.
 */
export const useSnackbar = () => {
  const { showSnackbar } = useContext(SnackbarContext);
  const memoizedShowSnackbar = useCallback(showSnackbar, [showSnackbar]);
  // Show snackbar with values + severity specified by the caller
  return { showSnackbar: memoizedShowSnackbar };
};

/**
 * Used at the top level of the app to provide the global snackbar.
 */
export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  // State has to be an object, or you get race conditions between various updates happening. Things run out of order.
  const [state, setState] = useState<ShowSnackbarProps & { snackbarOpen: boolean }>({
    snackbarOpen: false,
    title: '',
    // Text prop is not used for fullWidth snackbar
    text: '',
    severity: 'success',
    isFullWidth: false,
  });

  // This is the function that actually updates state when used by the various snackbar users.
  const setSnackbarValues = useCallback(
    ({
      title: callbackTitle,
      text: callbackText,
      severity: callbackSeverity,
      isFullWidth: callbackIsFullWidth,
    }: ShowSnackbarProps) =>
      setState({
        snackbarOpen: true,
        title: callbackTitle,
        text: callbackText,
        severity: callbackSeverity,
        isFullWidth: callbackIsFullWidth ?? false,
      }),
    [],
  );

  const closeSnackbar = () => {
    setState((prevState) => {
      return {
        ...prevState,
        snackbarOpen: false,
      };
    });
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar: setSnackbarValues }}>
      {children}
      {state.isFullWidth ? (
        <Snackbar
          onClose={closeSnackbar}
          open={state.snackbarOpen}
          autoHideDuration={4000}
          sx={{
            '&.MuiSnackbar-root': {
              marginX: 'auto',
              width: 'fit-content',
              minWidth: '300px',
            },
          }}
        >
          <SnackbarContent
            sx={{
              '&.MuiSnackbarContent-root': {
                backgroundColor: getSnackbarColor(state.severity),
                padding: 2,
                textAlign: 'center',
                width: '100%',
              },

              '& .MuiSnackbarContent-message': {
                padding: 0,
                width: '100%',
              },
            }}
            message={
              <Typography variant="bodyLargeHeavy" color="success.contrastText">
                {state.title}
              </Typography>
            }
          />
        </Snackbar>
      ) : (
        <Snackbar open={state.snackbarOpen} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert
            onClose={closeSnackbar}
            severity={state.severity}
            action={<Close onClick={closeSnackbar} />}
          >
            <AlertTitle>{state.title}</AlertTitle>
            {state.text && <Typography>{state.text}</Typography>}
          </Alert>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
};
